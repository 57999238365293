import React, { Component } from 'react';
import { object } from 'prop-types';
import { Scoped, k } from 'kremling';
import { debounce } from 'lodash';
import { api } from 'shared/api';
import { FormMapping } from './form-wizard/form-mapping.component';
import { PageHeader } from 'components/page-header/page-header';
import { Loader } from 'components/loader/loader.component';
import { Save } from 'components/save/save.component';
import { getForm, patchForm } from 'shared/forms.api';
import { Icon, PageHeaderMui } from 'components';
import { getWebFormScript } from './utils';
import { UserStateContext } from 'context/user-state-context';
export class WebFormEdit extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    match: object
  };
  state = {
    loading: true,
    saving: false
  };
  componentDidMount() {
    const {
      match
    } = this.props;
    getForm(match.params.formId).then(({
      data
    }) => {
      this.setState({
        loading: false,
        ...data
      });
    });
  }
  save = () => {
    const {
      id,
      name,
      redirect,
      fields
    } = this.state;
    patchForm(id, {
      name,
      redirect,
      fields
    }).then(() => {
      this.setState({
        saving: false
      });
    });
  };
  debounceSave = debounce(this.save, 500);
  update = state => {
    this.setState({
      saving: true,
      ...state
    });
    this.debounceSave();
  };
  render() {
    const {
      id,
      loading,
      saving,
      name,
      url,
      redirect,
      fields,
      entity_source
    } = this.state;
    if (loading) return <div className="wrapper">
          <Loader overlay />
        </div>;
    return <Scoped css={css}>
        <div className="wrapper">
          <PageHeaderMui type={name} icon={<Icon name="custom-segment" size={34} />} updateName={name => {
          this.update({
            name: name.name
          });
        }} updateNamePermission={this.context.hasPermission('forms.change_form') && entity_source !== 'google' && entity_source !== 'google_leads'} />
          <PageHeader name={name} actions={<Save saving={saving} />} />
          <div className="wrapper-scroll form-edit-wrapper">
            {entity_source !== 'google' && <>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Url</label>
                      <input type="text" className="form-control" disabled value={url} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Redirect</label>
                      <input type="text" className="form-control" value={redirect} placeholder="eg: https://yoursite.com/redirect-url" onChange={e => this.update({
                    redirect: e.target.value
                  })} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Form Code</label>
                  <input type="text" className="form-control form-control--read-only" readOnly onFocus={e => e.target.select()} value={getWebFormScript(id)} />
                </div>
              </>}
            <div className="form-group">
              <label>Field Mapping</label>
              <FormMapping fields={fields} onChange={fields => this.update({
              fields
            })} />
            </div>
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i17"] body,body[kremling="i17"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i17"] .form-edit-wrapper,[kremling="i17"].form-edit-wrapper {
  background-color: #fff;
  padding: 1.6rem;
}`,
  id: 'i17',
  namespace: 'kremling'
};